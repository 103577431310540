import React from 'react';
import Navbar from '../../Components/Navbar';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const ErrorPage = () => {
    const navigate = useNavigate();
    return (
        <>
         

                <Navbar />
                <section className=' d-flex justify-content-center align-items-center py-4' style={{ minHeight: ' calc(100vh - 56px)' }}>
                    <div className="container text-center">
                        <div className="row  justify-content-center">
                            <div className="col-lg-8 col-md-10 col-12">

                                <h2 className="fw-bold font-180 blue-text ">404</h2>
                                <h1 className="font-42 fw-bold" style={{ color: 'rgba(0, 0, 0, 1)' }}>Page Not Found</h1>
                                <p className="font-18 " style={{ color: 'rgba(0, 0, 0, 1)' }}>The page you're looking for can't be found. Double-check the URL and try again. Or click the button below.</p>
                                <div>
                                    <Button type='button' onClick={() => navigate('/plan-pricing')} className='btn-blue  m-2 p-3 rounded-5 font-16 w-full' > Pricing Page</Button>
                                    <Button type='button' onClick={() => navigate('/')} className='btn-gray m-2 p-3 rounded-5 font-16 w-full' >Go Home</Button>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>
        </>
    );
}

export default ErrorPage;
