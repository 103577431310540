import { Button } from '@mui/material';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import Navbar from '../../Components/Navbar';
import ScrollToTop from '../../Components/ScrollToTop';
import Footer from '../../Components/about/Footer';
import '../../style/contact.scss';

const ContactSale = () => {
    const [name, setName] = useState('');
    const [l_name, setL_Name] = useState('');
    const [email, setEmail] = useState('');
    const [company, setCompany] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [validationError, setValidationError] = useState(false);

    const handlesubmit =async (e) => {
        e.preventDefault();
        if (!name || !l_name || !email || company === "" || subject === "" || !message) {
            setValidationError(true);
            return;
        } else {
            try {
                const data = {
                    "first_name": name,
                    "last_name": l_name,
                    "email": email,
                    "company_size": company,
                    "how_can_our_sales_team_help": subject,
                    "other": message
                }
                const res = await fetch('https://script.google.com/macros/s/AKfycbzhcedYTNKQkzb3g8QYDSOIc2dSuV1bNmbEohwvUlIHPX9M5EOB6C9vB1fqI7mtPn-avw/exec', {
                    redirect: "follow",
                    method: "POST",
                    body: JSON.stringify(data),
                    headers: {
                      "Content-Type": "text/plain;charset=utf-8",
                    },
                  });
                console.log(res , '  response from api request ')
                Swal.fire({
                    icon: "success",
                    title: "We have received your message! Our sales representative will reach you out very soon.",
                });
              } catch (error) {
                console.error('There was an error making the POST request!', error);
              }
            setName('');
            setL_Name('');
            setEmail('');
            setCompany('');
            setSubject('');
            setMessage('');
            setValidationError(false);
        }
    }

    return (
        <>
            <ScrollToTop />
            <Navbar />
            <section className=' py-lg-5 py-md-5 py-sm-4 py-3 contact-sale'>
                <div className="container py-4">
                    <div className="row  justify-content-between">
                        <div className="col-lg-6 col-12  mt-lg-0 mt-4 px-lg-0 d-flex align-items-center">
                            <div>
                                <h1 className="font-60 accordion  fw- accordion  text-white">Contact our sales
                                    team</h1>
                                <h4 className=" text-white opacity-75 ">Built for small to medium size businesses,
                                    TimeLink provides your business with actionable insights and improves productivity.
                                    Contact us today.</h4>
                                <p className='font-14 fw-normal  text-white opacity-75 '>
                                    For technical issues and inquiries, please visit our Help Center or email us at <a className='text-white' href='mailto:support@timelink.au'>support@timelink.au</a>.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-12">
                            <div className="container-fluid">
                                <div className="row bg-white p-3 rounded-4">
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-2">
                                        <div>
                                            <label htmlFor="f_name" className="form-label font-12 text-uppercase">FIRST NAME*</label>
                                            <input type="text" name="name"
                                                value={name} onChange={(e) => setName(e.target.value)}
                                                className="form-control  font-12 rounded-5" id="f_name" required />
                                            {validationError && !name && <p className='m-0 ms-2 text-danger font-12 fw-medium '>
                                                First Name required
                                            </p>}
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-2">
                                        <div>
                                            <label htmlFor="l_name" className="form-label font-12 text-uppercase">Last NAME*</label>
                                            <input type="text" name="l_name"
                                                value={l_name} onChange={(e) => setL_Name(e.target.value)}
                                                className="form-control  font-12 rounded-5 " id="l_name" required />
                                            {validationError && !l_name && <p className='m-0 ms-2 text-danger font-12 fw-medium '>
                                                Last Name required
                                            </p>}
                                        </div>
                                    </div>
                                    <div className="col-12 mt-2">
                                        <div>
                                            <label htmlFor="email" className="form-label font-12 text-uppercase">Email*</label>
                                            <input type="email"
                                                name="email"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                className="form-control font-12 rounded-5 " id="email" required />
                                            {validationError && !email && <p className='m-0 ms-2 text-danger font-12 fw-medium '>
                                                Email is required
                                            </p>}
                                        </div>
                                    </div>
                                    <div className="col-12 mt-2">
                                        <div>
                                            <label className="form-label font-12 text-uppercase">Company Size*</label>
                                            <select name="company" className="form-select font-12 rounded-5 " aria-label="Default select example" required
                                                value={company}
                                                onChange={(e) => setCompany(e.target.value)}
                                            >
                                                <option value="">Please Select</option>
                                                <option value="Sales inquiry">Sales inquiry</option>
                                            </select>
                                            {validationError && !company && <p className='m-0 ms-2 text-danger font-12 fw-medium '>
                                                Company is required
                                            </p>}
                                        </div>
                                    </div>
                                    <div className=" col-12 mt-2">
                                        <div>
                                            <label htmlFor="subject" className="form-label font-12 text-uppercase">How can our sales team help?*</label>
                                            <select name="user_subject" className="form-select font-12 rounded-5 " aria-label="Default select example" required
                                                value={subject}
                                                onChange={(e) => setSubject(e.target.value)}
                                            >
                                                <option value="">Please Select</option>
                                                <option value="Sales inquiry">Sales inquiry</option>
                                                <option value="Technical Support">Technical Support</option>
                                                <option value="Billing and Payments">Billing and Payments</option>
                                                <option value="Account Assistance">Account Assistance</option>
                                                <option value="Feedback and Suggestions">Feedback and Suggestions</option>
                                                <option value="Report a Bug or Issue">Report a Bug or Issue</option>
                                                <option value="Partnership Opportunities">Partnership Opportunities</option>
                                                <option value="Marketing and Advertising">Marketing and Advertising</option>
                                                <option value="Product Information">Product Information</option>
                                                <option value="General Inquiry">General Inquiry</option>
                                            </select>
                                            {validationError && !subject && <p className='m-0 ms-2 text-danger font-12 fw-medium '>
                                                Subject is required
                                            </p>}
                                        </div>
                                    </div>
                                    <div className="col-12 mt-2">
                                        <div>
                                            <label htmlFor="msg" className="form-label font-12  text-uppercase"> Anything else you'd like us to know?</label>
                                            <textarea id="msg" name="user_msg"
                                                value={message}
                                                onChange={(e) => setMessage(e.target.value)}
                                                className='form-control font-12 rounded-4 ' cols="30" rows="5" placeholder='Your Message' required></textarea>
                                            {validationError && !message && <p className='m-0 ms-2 text-danger font-12 fw-medium '>
                                                Message is required
                                            </p>}
                                        </div>
                                        <div className=" d-flex align-items-center gap-2 mt-3">
                                            <input className="form-check-input" type="checkbox" id="agree" defaultChecked />
                                            <label className="form-check-label font-16" htmlFor="agree">
                                                I agree to TimeLink's <Link to="/term-condition" className='text-black'>Terms of Service</Link>  and <Link to="/privacy-policy" className='text-black'>Privacy Policy.</Link>*                                            </label>
                                        </div>
                                        <Button onClick={handlesubmit} type='button' className="btn-blue fw-normal mt-4 flex-shrink-0 w-auto p-2 rounded-5 font-16 px-4 text-capitalize " style={{ outline: "4px solid rgba(85, 89, 221, 0.4)" }}>Send Message</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}

export default ContactSale;
