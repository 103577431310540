import React from "react";
import logo from "../../assests/Logo.png";
import { FaFacebookSquare, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import ScrollToTop from "../../Components/ScrollToTop";
import { FaAngleDown } from "react-icons/fa6";
//
const Footer = ({ faqSectionRef }) => {
  const navigate = useNavigate();
  const [expanded, setExpanded] = React.useState("panel2");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const handleContact = async () => {
    navigate("/contact");
    window.scrollTo(0, 0);
  };
  const handleFaqs = async () => {
    // Navigate to /contact
    navigate("/contact");

    // Wait for navigation to complete
    await new Promise((resolve) => setTimeout(resolve, 500)); // Adjust the delay as needed

    // Get the FAQ section element by ID
    const faqsSection = document.getElementById("faqs-section");

    // Check if the section exists
    if (faqsSection) {
      // Scroll to the top of the FAQ section
      faqsSection.scrollIntoView({ behavior: "smooth" });
    } else {
      console.error("FAQs section not found!");
    }
  };

  return (
    <footer className="container mt-4 pt-4">
      <div className="row justify-content-between align-items-center">
        <div className="col-lg-6 col-md-6 col-12 text-lg-start text-md-start text-center">
          <Link to="/">
            {" "}
            <img src={logo} width={150} alt="image not found" />
          </Link>
        </div>
        <div className="col-lg-6 col-md-6 col-12 mt-lg-0 mt-md-0 mt-4 flex justify-end">
          <div>
            <Link
              to="https://instagram.com/timelink?igshid=OGQ5ZDc2ODk2ZA=="
              target="_blank"
              style={{ color: "rgba(0, 0, 0, 1)" }}
            >
              <FaInstagram size={25} />
            </Link>
            {/* <FaFacebookSquare className='mx-3' size={25} /> */}
            {/* <FaLinkedinIn size={25} /> */}
          </div>
        </div>
      </div>
      {/* -----------DESKTOP---FOOTER---START---- */}
      <div className="row justify-content-between mt-5 d-lg-flex d-md-flex d-none">
        <div className="col-lg-6 col-md-6 col-12">
          <div className="container-fluid">
            <div className="row text-uppercase">
              <div className="col-lg-4 col-md-6 co-12 mt-lg-0 mt-3 px-lg-0 px-md-0">
                <p
                  className="font-18 fw-bold fotter-line"
                  style={{ color: "rgba(0, 0, 0, 1)" }}
                >
                  SERVICE
                </p>
                <Link to="/plan-pricing" className="text-decoration-none">
                  <p className="font-14" style={{ color: "rgba(0, 0, 0, 1)" }}>
                    PLANS AND PRICING
                  </p>
                </Link>

                <p className="font-14" style={{ color: "rgba(0, 0, 0, 1)" }}>
                  -
                </p>
                <p className="font-14" style={{ color: "rgba(0, 0, 0, 1)" }}>
                  -
                </p>
              </div>
              <div className="col-lg-4 col-md-6 co-12 mt-lg-0 mt-3">
                <p
                  className="font-18 fw-bold fotter-line2"
                  style={{ color: "rgba(0, 0, 0, 1)" }}
                >
                  COMPANY
                </p>
                <Link to="/about" className="text-decoration-none">
                  <p className="font-14" style={{ color: "rgba(0, 0, 0, 1)" }}>
                    ABout us
                  </p>
                </Link>

                {/* <Link to='/blog' className='text-decoration-none'>

                  <p className="font-14" style={{ color: 'rgba(0, 0, 0, 1)' }}>
                    BLOG
                  </p>
                </Link> */}

                {/* <Link to='/testimonials' className='text-decoration-none'>

                  <p className="font-14" style={{ color: 'rgba(0, 0, 0, 1)' }}>
                    Testimonials
                  </p>
                </Link> */}
                <Link to="/term-condition" className="text-decoration-none">
                  <p className="font-14" style={{ color: "rgba(0, 0, 0, 1)" }}>
                    terms & conditions
                  </p>
                </Link>
                <Link to="/privacy-policy" className="text-decoration-none">
                  <p className="font-14" style={{ color: "rgba(0, 0, 0, 1)" }}>
                    privacy policy
                  </p>
                </Link>

                <Link to="/cookies-policy" className="text-decoration-none">
                  <p className="font-14" style={{ color: "rgba(0, 0, 0, 1)" }}>
                    cookies policy
                  </p>
                </Link>

                <p className="font-14" style={{ color: "rgba(0, 0, 0, 1)" }}>
                  -
                </p>
              </div>
              <div className="col-lg-4 col-md-6 co-12 mt-lg-0 mt-3">
                <p
                  className="font-18 fw-bold fotter-line3"
                  style={{ color: "rgba(0, 0, 0, 1)" }}
                >
                  HELP
                </p>
                <span onClick={handleContact} className="cursor-pointer">
                  <p className="font-14" style={{ color: "rgba(0, 0, 0, 1)" }}>
                    contact us
                  </p>
                </span>

                <span onClick={handleFaqs} className="cursor-pointer">
                  <p className="font-14" style={{ color: "rgba(0, 0, 0, 1)" }}>
                    faq
                  </p>
                </span>

                <p className="font-14" style={{ color: "rgba(0, 0, 0, 1)" }}>
                  help centre
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-5 col-md-6 col-12">
          <div
            className="p-4"
            style={{
              backgroundColor: "rgba(43, 43, 43, 0.1)",
              borderRadius: "28px",
            }}
          >
            <div className=" p-2">
              <p
                className="font-18 fw-bold text-uppercase"
                style={{ color: "rgba(0, 0, 0, 1)" }}
              >
                sign up today
              </p>
              <p className="font-14 " style={{ color: "rgba(0, 0, 0, 1)" }}>
                Get started with Time Link today and enhance your customer
                experience!
              </p>
              <div>
                <Link to="https://console.timelink.au/auth/signup">
                  <Button className="btn-blue  p-3 m-1 rounded-5 font-16">
                    sign up
                  </Button>
                </Link>
                <Link to="https://console.timelink.au/auth/login">
                  <Button className="btn-blue  p-3 m-1 rounded-5 font-16">
                    business log in
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* -----------DESKTOP---FOOTER---END---- */}
      {/* -----------MOBILE---FOOTER---START---- */}

      <div className="text-uppercase contact-accordin d-lg-none d-md-none d-block">
        <Accordion
          className="shadow-none border-0 !bg-white   before:hidden mt-3"
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            expandIcon={<FaAngleDown size={20} color="black" />}
            aria-controls="panel1d-content"
            id="panel1d-header"
          >
            <p
              className="fw-bold font-18 "
              style={{ color: "rgba(0, 0, 0, 1)" }}
            >
              SERVICE
            </p>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <Link to="/plan-pricing" className="text-decoration-none">
                <p className="font-14" style={{ color: "rgba(0, 0, 0, 1)" }}>
                  PLANS AND PRICING
                </p>
              </Link>

              <p className="font-14" style={{ color: "rgba(0, 0, 0, 1)" }}>
                -
              </p>
              <p className="font-14" style={{ color: "rgba(0, 0, 0, 1)" }}>
                -
              </p>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion
          className="shadow-none border-0 !bg-white   before:hidden mt-3"
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <AccordionSummary
            expandIcon={<FaAngleDown size={20} color="black" />}
            aria-controls="panel2d-content"
            id="panel2d-header"
          >
            <p
              className="fw-bold font-18 "
              style={{ color: "rgba(0, 0, 0, 1)" }}
            >
              COMPANY
            </p>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <Link to="/about" className="text-decoration-none">
                <p className="font-14" style={{ color: "rgba(0, 0, 0, 1)" }}>
                  ABout us
                </p>
              </Link>

              {/* <Link to='/blog' className='text-decoration-none'>

                  <p className="font-14" style={{ color: 'rgba(0, 0, 0, 1)' }}>
                    BLOG
                  </p>
                </Link> */}

              {/* <Link to='/testimonials' className='text-decoration-none'>

                  <p className="font-14" style={{ color: 'rgba(0, 0, 0, 1)' }}>
                    Testimonials
                  </p>
                </Link> */}
              <Link to="/term-condition" className="text-decoration-none">
                <p className="font-14" style={{ color: "rgba(0, 0, 0, 1)" }}>
                  terms & conditions
                </p>
              </Link>
              <Link to="/privacy-policy" className="text-decoration-none">
                <p className="font-14" style={{ color: "rgba(0, 0, 0, 1)" }}>
                  privacy policy
                </p>
              </Link>

              <Link to="/cookies-policy" className="text-decoration-none">
                <p className="font-14" style={{ color: "rgba(0, 0, 0, 1)" }}>
                  cookies policy
                </p>
              </Link>

              <p className="font-14" style={{ color: "rgba(0, 0, 0, 1)" }}>
                -
              </p>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion
          className="shadow-none border-0 !bg-white   before:hidden mt-3"
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
        >
          <AccordionSummary
            expandIcon={<FaAngleDown size={20} color="black" />}
            aria-controls="panel3d-content"
            id="panel3d-header"
          >
            <p
              className="fw-bold font-18 "
              style={{ color: "rgba(0, 0, 0, 1)" }}
            >
              HELP
            </p>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <span onClick={handleContact} className="cursor-pointer">
                <p className="font-14" style={{ color: "rgba(0, 0, 0, 1)" }}>
                  contact us
                </p>
              </span>

              <span onClick={handleFaqs} className="cursor-pointer">
                <p className="font-14" style={{ color: "rgba(0, 0, 0, 1)" }}>
                  faq
                </p>
              </span>

              <p className="font-14" style={{ color: "rgba(0, 0, 0, 1)" }}>
                help centre
              </p>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
      {/* -----------MOBILE---FOOTER---END---- */}

      <div className="pb-lg-3 pb-md-3 text-lg-start text-md-start text-center">
        <hr
          className="border-0 mt-1 mb-1 d-lg-none d-md-none d-block"
          style={{ height: "1px", opacity: "50%", backgroundColor: "#667085" }}
        />
        <p
          className="font-16 my-lg-0 my-md-0 my-3"
          style={{ color: "rgba(43, 43, 43, 0.3)" }}
        >
          <span className="d-lg-block d-md-block d-none">
            (c) 2024TIme Link Tech PTY LTD
          </span>
          <span className="d-lg-none d-md-none d-block">
            All rights reserved
          </span>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
