import { Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const BlogCard = (props) => {
  const navigate = useNavigate();
  return (
    <>
      <div
        onClick={() => navigate(props.path, { state: props.data })}
        className="bg-gray-200 h-full break-all flex justify-between flex-col	border rounded-3xl cursor-pointer"
      >
        <div>
          <img
            src={props.img}
            alt="image not found"
            className="w-100 h-56 rounded-t-3xl object-cover"
          />
          <div className="p-4">
            {/* <Button
              className="btn-blue  px-3 rounded-5 font-16"
              style={{ transform: "translate(0px, -20px)" }}
            >
              {props.btn}
            </Button> */}
            <h4 className="font-22 fw-bold">{props.head}</h4>
            <RichTextComponent content={props.phara} />
            <hr
              className="border-0 mt-1 mb-1"
              style={{
                height: "1.5px",
                backgroundColor: "rgba(255, 255, 255, 0.1)",
              }}
            />
          </div>
        </div>
        <div className="align-items-center d-flex px-4 pb-4">
          <img
            src={props.user}
            className="rounded-5 w-12 h-12 shadow-md object-cover"
            alt="image not found"
          />
          <div className="ms-3">
            <p className="text-base fw-bold mb-0 text-uppercase">
              {props.userName}
            </p>
            <p className="text-sm mb-0">{props.date}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogCard;

function RichTextComponent({ content }) {
  return <div dangerouslySetInnerHTML={{ __html: content }} />;
}
